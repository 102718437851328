import * as Yup from 'yup';
import { defaultFileMimeTypes } from '../../../../shared/components/BroadcastInputTextPopup/helpers';

export const MAX_TITLE_LENGTH = 100;
export const MAX_DESCRIPTION_LENGTH = 300;

const nameSchema = Yup.string()
	.trim()
	.test('title_required', 'Placeholder name is required', value => {
		if (value) {
			return !!value.trim().length;
		}

		return false;
	})
	.min(2, 'Placeholder name should be more than 2 characters')
	.max(MAX_TITLE_LENGTH, `Placeholder name must be at most ${MAX_TITLE_LENGTH} characters`);

const descriptionSchema = Yup.string()
	.test('MIN_DESCRIPTION_LENGTH', 'Description should be more than 2 characters', value => {
		if (value) {
			const elem = document.createElement('div');
			elem.innerHTML = value;
			return elem.innerText.trim().length >= 2 || !elem.innerText.trim().length;
		}
		return true;
	})
	.test('MAX_DESCRIPTION_LENGTH', `Description must be at most ${MAX_DESCRIPTION_LENGTH} characters`, value => {
		if (value) {
			const elem = document.createElement('div');
			elem.innerHTML = value;
			return elem.innerText.trim().length <= MAX_DESCRIPTION_LENGTH;
		}
		return true;
	})
	.test('required', 'Description is required', value => {
		if (value) {
			const elem = document.createElement('div');
			elem.innerHTML = value;
			return !!(value && elem.innerText.trim().length);
		}
		return false;
	});

export const placeholderValidation = Yup.object().shape({
	name: nameSchema,
	description: descriptionSchema,
	imagePath: Yup.string().required('Photo is required'),
});

export const defaultPhotoTypes = 'image/jpeg, image/jpg, image/png, image/bmp';

export const getIsUploadedPhotoValid = (photo: File, maxMegabyteSize = 5): boolean => {
	const MAX_PHOTO_SIZE_IN_MEGABYTES = maxMegabyteSize;
	const megabyteInBytes = 1024 * 1024;
	const photoSizeInMegabytes = photo.size / megabyteInBytes;

	return defaultFileMimeTypes.includes(photo.type) && photoSizeInMegabytes < MAX_PHOTO_SIZE_IN_MEGABYTES;
};
