import styles from './UploadQuotesUnput.module.scss';
import { ReactComponent as UploadSVG } from 'assets/image/upload-icon.svg';
import * as XLSX from 'xlsx';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { IQuoteRequest } from '@joc/api-gateway';

interface IExcelQuoteRequest {
	message: string;
	startDate: number;
}

interface IUploadQuotesInputProps {
	handleInput: (quotes: Array<IQuoteRequest>) => void;
}

export const UploadQuotesInput: FC<IUploadQuotesInputProps> = ({ handleInput }) => {
	const [file, setFile] = useState<File>();
	const [errorMessages, setErrorMessages] = useState<Array<string>>([]);

	const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.target.files && setFile(Array.from(e.target.files)[0]);
	};

	useEffect(() => {
		file && parseFile(file);
	}, [file]);

	const excelDateToJSDate = (excelDate: number): Date => {
		const excelEpoch = new Date(1899, 11, 30); // December 30, 1899
		const millisecondsPerDay = 24 * 60 * 60 * 1000;

		const convertedDate = new Date(excelEpoch.getTime() + excelDate * millisecondsPerDay);

		// Set time to noon (12:00:00)
		return new Date(convertedDate.getFullYear(), convertedDate.getMonth(), convertedDate.getDate(), 12, 0, 0);
	};

	const parseFile = (file: File) => {
		const reader = new FileReader();

		reader.onload = e => {
			const data = e.target?.result;
			const excelData = XLSX.read(data, { type: 'binary' });
			const sheetName = excelData.SheetNames[0];
			const sheet = excelData.Sheets[sheetName];

			const getParsedData = (sheet: XLSX.WorkSheet) => {
				const rowsArray = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as Array<Array<string>>;
				const rowIndex = rowsArray.findIndex(row => row.includes('Start date'));
				const ref = sheet['!ref']!;

				let range = ref;

				if (rowIndex > 0) {
					const [refStart, refEnd] = ref.split(':');
					const [refStartX] = refStart.split('');
					const newRefStart = `${refStartX}${rowIndex + 1}`;
					range = `${newRefStart}:${refEnd}`;
				}

				return XLSX.utils.sheet_to_json(sheet, {
					range,
					defval: '',
					blankrows: false,
				}) as Array<IExcelQuoteRequest>;
			};

			const parsedData = getParsedData(sheet);

			const normalizeParsedData = parsedData.map(parsedUserValuesObj => {
				let normalizedUserValuesObj = {} as IExcelQuoteRequest;

				Object.keys(parsedUserValuesObj).forEach(key => {
					const normalizeKeyName = key
						.split(' ')
						.filter(key => key !== '-')
						.map((word, idx) => {
							word = word.toLowerCase();
							if (idx !== 0) word = word[0].toUpperCase() + word.slice(1);
							return word;
						})
						.join('');

					normalizedUserValuesObj = {
						...normalizedUserValuesObj,
						[normalizeKeyName]: parsedUserValuesObj[key as keyof IExcelQuoteRequest],
					};
				});

				return normalizedUserValuesObj;
			});

			const importedDailyQuotes: Array<IQuoteRequest> = normalizeParsedData.map(quote => ({
				...quote,
				startDate: excelDateToJSDate(quote.startDate),
			}));

			handleInput(importedDailyQuotes);
		};

		reader.readAsBinaryString(file);
	};

	return (
		<div className={styles.wrap}>
			<div className={styles.input}>
				<UploadSVG className={styles.uploadIcon} />
				<div className={styles.uploadText}>
					<input
						id="file-upload"
						type="file"
						accept=".csv, application/vnd.oasis.opendocument.spreadsheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						onInput={handleUpload}
						// @ts-ignore
						// eslint-disable-next-line no-return-assign
						onClick={e => (e.target.value = null)}
					/>
					<label htmlFor="file-upload" className={styles.uploadLabel}>
						Click to upload
					</label>
					<span>or drag and drop</span>
				</div>
			</div>
			<div className={styles.errorsBlock}>
				{errorMessages.map(message => (
					<span className={styles.errorMessage}>{message}</span>
				))}
			</div>
		</div>
	);
};
