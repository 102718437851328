import React, { FC, useState } from 'react';
import moment from 'moment-mini';

// components
import { MenuItem, IconButton } from '@material-ui/core';
import StyledMenu from '../../../../../Menu';

// icons
import { ReactComponent as DeleteSvg } from 'assets/image/trash-light.svg';
import { ReactComponent as ThreeDotsSvg } from 'assets/image/threeDots.svg';

// types
import { IQuoteRequest } from '@joc/api-gateway';

// styles
import styles from './ImportedQuotesTable.module.scss';

interface IImportedQuotesProps {
	quotes: Array<IQuoteRequest>;
	handleQuoteDelete: (quoteIndex: number) => void;
}

export const ImportedQuotesTable: FC<IImportedQuotesProps> = ({ quotes, handleQuoteDelete }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [openItemIndex, setOpenItemIndex] = useState<number | null>(null);

	const handleActionsButtonClose = () => {
		setAnchorEl(null);
		setOpenItemIndex(null);
	};

	const handleDeleteClick = (quoteIndex: number) => {
		handleActionsButtonClose();
		handleQuoteDelete(quoteIndex);
	};

	const handleActionsButtonClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
		setAnchorEl(event.currentTarget);
		setOpenItemIndex(index);
	};

	return (
		<table className={styles.quotesTable}>
			<thead>
				<tr>
					<th className={styles.quotesTableDate}>Date</th>
					<th className={styles.quotesTableName}>Daily Quotes</th>
					<th aria-label="Actions" />
				</tr>
			</thead>
			<tbody>
				{quotes.map((quote, index) => (
					<tr key={index}>
						<td className={styles.quotesTableDate} title={moment(quote.startDate).format('DD MMM')}>
							<span>{moment(quote.startDate).format('DD/MM/YYYY')}</span>
						</td>
						<td className={styles.quotesTableName} title={quote.message}>
							<span>{quote.message}</span>
						</td>
						<td align="center">
							<IconButton
								aria-label="actions"
								aria-controls={openItemIndex === index ? 'long-menu' : undefined}
								onClick={event => handleActionsButtonClick(event, index)}
							>
								<ThreeDotsSvg />
							</IconButton>
							<StyledMenu
								open={openItemIndex === index}
								anchorEl={anchorEl}
								onClose={handleActionsButtonClose}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
							>
								{/*<MenuItem className={styles.menuItem} onClick={() => handleEditClick(index)}>*/}
								{/*	<div className={styles.actionsContainer}>*/}
								{/*		<EditSvg style={{ width: '16px', height: '16px' }} title="Delete quote" />*/}
								{/*		<span className={styles.actionsText}>Edit</span>*/}
								{/*	</div>*/}
								{/*</MenuItem>*/}
								<MenuItem className={styles.menuItem} onClick={() => handleDeleteClick(index)}>
									<div className={styles.actionsContainer}>
										<DeleteSvg style={{ width: '16px', height: '16px' }} title="Delete quote" />
										<span className={styles.actionsText}>Delete</span>
									</div>
								</MenuItem>
							</StyledMenu>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};
