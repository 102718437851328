import React, { ChangeEvent, Dispatch, FC, SetStateAction, useMemo, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
// Images
import LogoImage from 'assets/image/logo.svg';
import UserIcon from 'assets/image/user-icon.svg';
// Core
import { DashboardSettingsResponse } from '@joc/api-gateway/lib/api-client';
import { ILiveScreenInfo } from 'redux/dashboard-service/types';
// Redux
import { useSelector } from 'react-redux';
import { selectorGetLiveScreen } from 'redux/dashboard-service/selector';
// Formik
import { Field, Form, useFormikContext } from 'formik';
// Components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
// Styles
import cx from 'classnames';
import styles from './LiveScreenForm.module.scss';

interface LiveScreenFormProps {
	mediaPath: string;
	file: Blob | null;
	setFile: Dispatch<SetStateAction<Blob | null>>;
	editMode: boolean;
	onCancelEditMode: () => void;
	// eslint-disable-next-line no-unused-vars
	onSaveChanges: (values: ILiveScreenInfo) => Promise<void>;
}

export const LiveScreenForm: FC<LiveScreenFormProps> = ({
	mediaPath,
	file,
	setFile,
	editMode,
	onCancelEditMode,
	onSaveChanges,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const { dirty, isValid, values, resetForm, setFieldValue } = useFormikContext<any>();
	const liveScreenInfo = useSelector(selectorGetLiveScreen);
	const { recentlyVolunteerName, recentlyOppoName, recentlyVolunteerAvatar } =
		liveScreenInfo as DashboardSettingsResponse;

	// tslint:disable-next-line:no-shadowed-variable
	const getFileType = (file: string) => (file.split('.').pop() === 'mp4' ? 'video' : 'image');

	const [showError, setShowError] = useState(false);

	const initMedia = { path: mediaPath, type: getFileType(mediaPath) };

	const [media, setMedia] = useState(initMedia);

	const handleOpenMedia = () => {
		if (inputRef.current) inputRef.current.click();
	};

	const handleFileChange = (e: any) => {
		const selectedFile = e.target.files[0];
		// Перевірка на розмір файлу (не більше 5 МБ)
		if (selectedFile.size <= 5 * 1024 * 1024) {
			setShowError(false);
			setFile(selectedFile);
			setMedia({
				path: URL.createObjectURL(selectedFile),
				type: selectedFile.type.startsWith('video/') ? 'video' : 'image',
			});
		} else {
			setShowError(true);
		}
	};

	const onCancelEdit = () => {
		resetForm();
		setShowError(false);
		setMedia(initMedia);
		onCancelEditMode();
	};

	const convertedTime = useMemo(() => {
		return liveScreenInfo ? Math.round(liveScreenInfo?.spendTime / 3600) : 'No hours';
	}, [liveScreenInfo?.spendTime]);

	const handleChangeFieldValue = (event: ChangeEvent<HTMLTextAreaElement>, key: string) => {
		setFieldValue(key, event.target.value).then();
	};

	return (
		<Form>
			<div className={styles.container}>
				<div className={styles.board}>
					<div className={styles.top}>
						<div className={styles.top__organization}>
							<img className={styles.top__organization__avatar} src={LogoImage} alt="avatar" />
							<span className={styles.top__organization__name}>Just One Chesed</span>
						</div>
						<div
							className={cx(styles.top__title_wrapper, {
								[styles.top__title_edit]: editMode,
							})}
						>
							<Field
								className={styles.top__title}
								name="title"
								id="title"
								value={values.title}
								disabled={!editMode}
								onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
									handleChangeFieldValue(event, 'title')
								}
								component={TextareaAutosize}
							/>
						</div>
					</div>

					<div className={cx(styles.card, styles.card_first)}>
						<span className={styles.card__title}>World wide hours</span>
						<span className={styles.card__count}>{convertedTime}</span>
					</div>

					<div className={cx(styles.card, styles.card_second)}>
						<span className={styles.card__title}>Total Opportunities</span>
						<span className={styles.card__count}>{liveScreenInfo?.opportunitiesCount}</span>
					</div>

					<div className={cx(styles.card, styles.card_third)}>
						<span className={styles.card__title}>Volunteer spotlight</span>
						{recentlyVolunteerName ? (
							<div className={styles.card__volunteer}>
								{/*{recentlyVolunteerAvatar ? (*/}
								{/*	<img*/}
								{/*		className={styles.card__volunteer__avatar}*/}
								{/*		src={recentlyVolunteerAvatar}*/}
								{/*		alt="volunteer avatar"*/}
								{/*	/>*/}
								{/*) : (*/}
								{/*	<img*/}
								{/*		className={styles.card__volunteer__avatar}*/}
								{/*		src={UserIcon}*/}
								{/*		alt="volunteer avatar"*/}
								{/*	/>*/}
								{/*)}*/}
								<div className={styles.card__volunteer__avatar}>
									{recentlyVolunteerName.split('').join(' ')}
								</div>
								{/*<div className={styles.card__volunteer__info}>*/}
								{/*	<span className={styles.card__volunteer__name} title={recentlyVolunteerName}>*/}
								{/*		{recentlyVolunteerName}*/}
								{/*	</span>*/}
								{/*</div>*/}
							</div>
						) : null}
						{recentlyOppoName ? (
							<div
								className={cx(styles.card__subTitle, styles.recentlyOppo, {
									[styles.card__volunteer__oppo_padding]: recentlyVolunteerName.trim().length >= 43,
									[styles.card__volunteer__oppo]: recentlyOppoName.trim().length >= 38,
								})}
								title={recentlyOppoName}
							>
								<span className={styles.card__subTitle_bold}>Opportunity: </span>
								<span className={styles.card__opponame}>{recentlyOppoName}</span>
							</div>
						) : null}
					</div>

					<div
						className={cx(styles.bottom, {
							[styles.bottom_edit]: editMode,
						})}
					>
						<img src={LogoImage} className={styles.bottom__avatar} alt="logo" />
						<Field
							className={cx(styles.bottom__subTitle, {
								[styles.bottom__subTitle_edit]: editMode,
							})}
							name="subTitle"
							id="subTitle"
							value={values.subTitle}
							disabled={!editMode}
							onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
								handleChangeFieldValue(event, 'subTitle')
							}
							component={TextareaAutosize}
						/>
					</div>
					<div className={styles.media}>
						{editMode && (
							<ButtonDefault
								parentClassName={styles.upload}
								title="+ Upload Media"
								classList={['primary', 'extra_md']}
								clickHandler={handleOpenMedia}
							/>
						)}
						{showError && (
							<span className={styles.error}>
								File is too large. Please choose a file smaller than 5 MB.
							</span>
						)}
						{media.type === 'video' ? (
							// tslint:disable-next-line:jsx-boolean-value
							<video className={styles.media__file} muted autoPlay playsInline loop>
								<source src={media.path} type="video/mp4" />
							</video>
						) : (
							<img
								className={cx(styles.media__file, {
									[styles.media__file_edit]: editMode,
								})}
								src={media.path}
								alt="dashboard media"
							/>
						)}
					</div>
					{editMode && (
						<div className={styles.button}>
							<ButtonDefault
								submitType={true}
								classList={['primary', 'md']}
								parentClassName={(!file && (!dirty || !isValid) && styles.disable_submit) || ''}
								title="Save"
								disabled={!file && (!dirty || !isValid)}
								clickHandler={() => onSaveChanges(values)}
							/>
							<ButtonDefault
								classList={['secondary', 'md']}
								parentClassName={styles.cancel_button}
								title="Cancel"
								clickHandler={onCancelEdit}
							/>
						</div>
					)}
				</div>
			</div>
			<input
				onChange={handleFileChange}
				accept="image/png, image/gif, image/jpeg, video/mp4"
				ref={inputRef}
				className={styles.input}
				type="file"
			/>
		</Form>
	);
};
